/* bootstrap styles */
@import '~bootstrap/scss/bootstrap.scss';
@import '~ngx-bootstrap/datepicker/bs-datepicker.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

/* toastr styles */
@import '~ngx-toastr/toastr';
@import '~ngx-toastr/toastr-bs4-alert';

/* font awesome styles */
@import "@fortawesome/fontawesome-free/css/all.css"; 